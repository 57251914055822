<template>
  <div class="login" v-cloak>
    <div class="login-box">
      <div class="title">大嘴鸟AI营销平台</div>
      <div>
        <el-input type="text" class="phone" placeholder="账号" v-model="phone"></el-input>
      </div>
      <div>
        <el-input class="passwd" placeholder="请输入密码" v-model="passwd" show-password></el-input>
      </div>
      <el-checkbox v-model="checked" class="check-box">记住账号和密码</el-checkbox>
      <div>
        <el-button type="primary" class="button-login" @click="do_login">登录</el-button>
        <div class="bottom-name">2022 - 大嘴鸟AI营销平台.</div>
      </div>
    </div>
    <h2 class="number">浙ICP备19022148号</h2>
  </div>
</template>

<script>
import { Login } from '../../request/api'
import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      phone: '',
      passwd: '',
      checked: false
    }
  },
  methods: {
    async do_login () {
      if (!this.phone || !this.passwd) {
        this.$message.error('账号或密码不能为空')
      } else {
        let postData = {
          phone: this.phone,
          password: this.passwd
        }
        const res = await Login(postData)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        this.setUser(res.data.data)
        // 存储本地
        localStorage.setItem('usersinfomation', JSON.stringify(res.data.data))
        this.$router.push('/shopping')
      }
    },
    // 将m_login中的方法 映射到当前页面
    ...mapMutations('m_login', ['setUser'])
  }
}
</script>

<style lang="less" scoped>
.login {
  height: 100%;
  .number {
    color: #66b1ff;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 50%;
  }
  .login-box {
    text-align: center;
    margin-top: 5%;
    .title {
      color: #6c6c6c;
      font-size: 22px;
      font-weight: 700;
    }
    .phone {
      width: 300px;
      margin-top: 20px;
    }
    .passwd {
      width: 300px;
      margin-top: 15px;
    }
    .check-box {
      margin-left: -150px;
      margin-top: 10px;
    }
    .button-login {
      width: 300px;
      margin-top: 20px;
    }
    .bottom-name {
      font-size: 12px;
      margin-top: 4px;
    }
  }
}
</style>